import { useState, useEffect } from 'react';
import {
  Box, Typography, Slide, Button, Checkbox, FormGroup, FormControlLabel
} from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import {
  Link, useNavigate, useParams, useSearchParams
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NotificationContainer, OrderForm, LogoContainer } from '../../components';
import {
  selectError, startOrder, validateSignature, selectValid
} from './orderSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import './OrderPage.css';
import { useLogOut } from '../../hooks/useLogOut';

export const OrderPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { type } = useParams();
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectError);
  const logOut = useLogOut();
  const [searchParams] = useSearchParams();
  const valid = useAppSelector(selectValid);

  const [termsConfirmed, setTermsConfirmed] = useState(false);

  // TODO: Add better check somewhere when backend is ready
  const isOrderReady = type === 'onnistui';
  const isOrderCanceled = type === 'peruuntui';
  const isOrderMalformed = type === 'virhe';

  useEffect(() => {
    if (searchParams.get('signature')) {
      const filteredParams = Array.from(searchParams.entries()).filter(([key]) => (key.startsWith('checkout-') || key === 'signature'));
      // eslint-disable-next-line max-len
      const paramsToCheck: { [key: string]: string } = filteredParams.reduce((acc: { [key: string]: string }, [key, value]: [string, string]) => {
        if (key.startsWith('checkout-') || key === 'signature') {
          acc[key] = value;
        }
        return acc;
      }, {});
      dispatch(validateSignature(paramsToCheck));
      if (valid !== undefined && valid !== true) {
        navigate('/tilaus/virhe');
      }
    }
  }, [valid]);

  const confirm = () => {
    if (!isOrderReady) {
      dispatch(startOrder());
    } else {
      navigate('/');
    }
  };

  const cancel = () => {
    logOut();
  };

  return (
    <Box className="login-page setup-page">
      <LogoContainer showMenu={false} />
      <Slide direction="down" in>
        <Box className={`authenticator-container setup-container ${isOrderReady ? 'success' : ''}`}>
          <header className="setup-header">
            <Typography component="h2" variant="h5" className="primary-title">
              {isOrderReady ? t('order.orderWasSuccessful') : t('order.basicPlan')}
            </Typography>
          </header>
          {!isOrderReady && (
            <>
              <Box className="content-container details-container">
                {isOrderCanceled && (
                  <NotificationContainer type="info">
                    {t('order.orderWasCanceled')}
                  </NotificationContainer>
                )}
                {(isOrderMalformed || error) && (
                  <NotificationContainer type="info">
                    {t('common.genericError')}
                  </NotificationContainer>
                )}
                <OrderForm />
              </Box>
              <FormGroup className="terms">
                <FormControlLabel
                  control={(
                    <Checkbox onClick={() => setTermsConfirmed(!termsConfirmed)} />
                  )}
                  label={(
                    <>
                      <span>{t('terms.accept')} </span>
                      <Link to="/Kayttoehdot, Digitaalinen talokirja.pdf" target="_blank">{t('terms.terms')} </Link>
                      <span> {t('terms.and')} </span>
                      <Link to="/Tietosuojaseloste, Digitaalinen talokirja.pdf" target="_blank">{t('terms.privacyPolicy')}</Link>
                    </>
                  )}
                />
              </FormGroup>
            </>
          )}
          <Button
            disabled={!termsConfirmed && !isOrderReady}
            variant="contained"
            onClick={confirm}
          >
            {isOrderReady ? t('button.start') : t('button.order')}
          </Button>
          {!isOrderReady && (
            <Button onClick={cancel}>
              {t('button.cancel')}
            </Button>
          )}
        </Box>
      </Slide>
    </Box>
  );
};
