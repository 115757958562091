import { Box } from '@mui/material';
import { PropertyMenu } from '../propertyMenu/PropertyMenu';
import './LogoContainer.css';
import { useAppSelector } from '../../hooks';
import { selectIsMobile } from '../../app/appSlice';

interface LogoContainerProp {
  showMenu: boolean;
}

export const LogoContainer = ({ showMenu } : LogoContainerProp) => {
  const isMobile = useAppSelector(selectIsMobile);

  return (
    <Box className="logo-container">
      <img
        alt="logo"
        src="/Talosta_logo_invert.png"
        style={{ width: isMobile ? '100px' : '150px' }}
      />
      {showMenu && !isMobile && (<PropertyMenu />)}
    </Box>
  );
};
