import { Authenticator, View, Image } from '@aws-amplify/ui-react';
import { Box } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import './LoginPage.css';

const customComponents = {
  Header: () => (
    <View textAlign="center" padding="42px 0 20px">
      <Image
        style={{ width: '50%' }}
        alt="Talosta logo"
        src="/Talosta_logo.png"
      />
    </View>
  )
};

export const LoginPage = () => (
  <Box className="login-page">
    <Box className="authenticator-container">
      <Authenticator loginMechanisms={['email']} signUpAttributes={['name']} components={customComponents} />
    </Box>
  </Box>
);
